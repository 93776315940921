import { DatePicker, Dropdown, TextField } from '@fluentui/react';
import styled from 'styled-components';
import { color } from './styles';

/********************************************************
 * VIEW REGIONS
 ********************************************************/
const OnixTableView = {
  Container: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  `,
  GridPanel: styled.div`
    display: flex;
    flex: 1 1 auto;
    border: 1px solid rgb(237, 235, 233);
    height: 100%;
    vertical-align: top;
    position: relative;
  `,
  FilterPanel: styled.div`
    display: table-cell;
    border-top: 1px solid rgb(237, 235, 233);
    height: 100%;
    position: relative;
  `,
  Divider: styled.div`
    display: flex;
    height: 100%;
    cursor: ew-resize;
    width: 8px;
    background: none;
    border-top: solid 1px rgb(237, 235, 233);
  `,
};

const OnixViewFilterPanel = {
  Container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: center;
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
  `,
  Header: styled.div`
    align-items: center;
    overflow: hidden;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
    padding: 23px 27px 0px 27px;
    min-width: 248px;
    max-width: 330px;
  `,
  Body: styled.div`
    min-width: 248px;
    max-width: 330px;
    z-index: 1;
    padding: 0px;
    padding: 0px 27px 23px 27px;
  `,
};

/********************************************************
 * LAYOUT
 ********************************************************/
const GridItemsLayout = {
  Container: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
  Grid: styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 30px;
    overflow: hidden;
    padding: 0px;
  `,
  StatusBar: styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 30px;
    overflow: hidden;
    padding: 0px;
  `,
};

const ListItemsLayout = {
  Container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    min-width: 278px;
  `,
  TopBar: styled.div`
    flex: 0;
    border-bottom: solid 1px darkgray;
    padding: 0px;
  `,
  List: styled.div`
    flex: 1;
    overflow: hidden;
    padding: 0px;
    position: relative;
  `,
};

/********************************************************
 * CONTROLS
 ********************************************************/
const Container = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const DivFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const NotHitMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 100px;
  text-align: center;
`;
// @ts-ignore
const StyledTextField = styled(TextField)`
  & input[type='text'] {
    height: 32px;
    border: none;
  }
`;

// @ts-ignore
const StyledDatePicker = styled(DatePicker)`
  & .ms-TextField.is-disabled {
    & .ms-TextField-fieldGroup {
      border: none !important;
    }
  }
  & .ms-TextField-fieldGroup {
    background-color: transparent;
  }

  & input[type='text'] {
    height: 32px;
    font-size: 14px;
    opacity: 1;
  }

  & input[disabled] {
    border: none;
    background-color: rgb(243, 242, 241);
  }

  & .ms-TextField-suffix {
    padding: 0;
  }

  & .ms-TextField-suffix i:hover {
    font-weight: 800 !important;
  }
`;
// @ts-ignore
const StyledDropdown = styled(Dropdown)`
  .ms-Dropdown {
    border-color: ${color.$grey130};
    & .ms-Dropdown-title {
      border-color: ${color.$grey130};
      &:hover {
        border-color: ${color.$grey130};
      }
    }
  }
`;

// @ts-ignore
const StyledCustomSearchBox = styled(TextField)`
  & .ms-TextField-fieldGroup {
    border: 1px solid ${color.$grey110};
    &:hover {
      border: 1px solid ${color.$grey160};
    }

    input {
      font-size: 14px;
      ::placeholder {
        font-size: 14px;
      }
    }

    .ms-TextField-suffix {
      width: 56px;
      background: unset;
      padding: 0px;

      .ms-Button {
        position: absolute;
        top: 0px;
        &:hover {
          background: transparent;
        }

        &.btn-search {
          right: 0px;
        }
        &.btn-clear-text {
          right: 25px;
        }
      }
    }
  }

  & input[type='text'] {
    height: 32px;
    border: none;
  }

  &.is-disabled {
    .ms-TextField-suffix {
      background: rgb(243, 242, 241);
    }
  }
`;

export {
  Container,
  DivFlexColumn,
  DivFlexRow,
  GridItemsLayout,
  ListItemsLayout,
  NotHitMessage,
  OnixTableView,
  OnixViewFilterPanel,
  StyledCustomSearchBox,
  StyledDatePicker,
  StyledDropdown,
  StyledTextField,
};
