import { Dialog as DialogFluent, DialogFooter, IDialogProps } from '@fluentui/react/lib/Dialog';
import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import './index.scss';

interface Props extends IDialogProps {
  isHidden: boolean;

  children?: string | ReactElement;
  footerChildren?: ReactElement;
}

export default function Dialog(props: Props) {
  const { onDismiss, isHidden: isHiddenProps, children, footerChildren } = props;

  const [isHidden, setIsHidden] = useState(isHiddenProps);

  const onDismissChange = useCallback(() => {
    if (onDismiss) {
      onDismiss();
      return;
    }
    setIsHidden(true);
  }, [onDismiss]);

  useEffect(() => {
    setIsHidden(isHiddenProps);
  }, [isHiddenProps]);

  return (
    <>
      <DialogFluent {...props} hidden={isHidden} onDismiss={onDismissChange} className="common-dialog">
        {children}

        {footerChildren && (
          <DialogFooter>
            {footerChildren.type.toString() === Fragment.toString() && footerChildren.props?.children?.length > 1
              ? footerChildren.props.children
              : footerChildren}
          </DialogFooter>
        )}
      </DialogFluent>
    </>
  );
}
