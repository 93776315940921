import { ActionButton } from '@fluentui/react';
import { LanguageEnum } from '@onix/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updatePreferredLanguage } from '../../../../../services/account.service';
import { getSupportedLanguages } from '../../../../../services/common.service';
import { showErrorDialogWithResponse } from '../../../../../services/dialog.service';
import { hiddenModal } from '../../../../../services/modal.service';
import { appDispatch } from '../../../../../states/store';
import { UserActions } from '../../../../../states/user/userSlice';
import './ChangeLanguage.scss';

export function ChangeLanguage() {
  const dispatch = appDispatch;
  const { i18n: i18next } = useTranslation();
  const [languages, setLanguages] = useState<{ id: LanguageEnum; name: string; code: string }[]>([]);

  useEffect(() => {
    const currentLanguages = getSupportedLanguages();
    setLanguages(currentLanguages);
  }, []);

  const changeLanguage = async (languageId: LanguageEnum, languageCode: string) => {
    try {
      await updatePreferredLanguage(languageId);
      i18next.changeLanguage(languageCode, () => {
        hiddenModal();
        dispatch(UserActions.updateLanguage(languageId));
      });
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  return (
    <>
      {languages.map((language) => (
        <ActionButton key={language.id} onClick={() => changeLanguage(language.id, language.code)} className="change-language-button">
          {language.name}
        </ActionButton>
      ))}
    </>
  );
}
