import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from 'axios';
import qs from 'qs';

const axiosInstance = (headers: any, handleErrorAutomatic: boolean, cancelTokenSource?: CancelTokenSource): AxiosInstance => {
  const instance = axios.create({
    headers: Object.assign({}, headers),
    cancelToken: cancelTokenSource?.token ?? undefined,
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response?.data != null) {
        return response?.data;
      }
      return response;
    },
    (error: any) => {
      const result = error?.response;
      if (result) {
        return Promise.reject(result);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAsync = (url: string, request?: any, headers?: any, handleErrorAutomatic: boolean = true): Promise<any> => {
  return axiosInstance(headers, handleErrorAutomatic).get(url, {
    params: request,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const downloadAsync = async (url: string): Promise<any> => {
  return await axios.get(url, { responseType: 'blob' });
};

export const uploadAsync = async (url: string, data: any): Promise<any> => {
  return await axios.post(url, data, { responseType: 'blob' });
};

export const postAsync = (
  url: string,
  request?: any,
  headers?: any,
  handleErrorAutomatic: boolean = true,
  cancelTokenSource?: CancelTokenSource
): Promise<any> => {
  return axiosInstance(headers, handleErrorAutomatic, cancelTokenSource).post(url, request);
};

export const putAsync = (url: string, request?: object, headers?: any, handleErrorAutomatic: boolean = true): Promise<any> => {
  return axiosInstance(headers, handleErrorAutomatic).put(url, request);
};

export const deleteAsync = (url: string, headers?: any, payload?: any, handleErrorAutomatic: boolean = true): Promise<any> => {
  return axiosInstance(headers, handleErrorAutomatic).delete(url, { data: payload });
};
