import { ActionButton, Link, PersonaSize } from '@fluentui/react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApplicationVersion } from '../../../../core/constants/application.constants';
import { RouteConstants } from '../../../../core/constants/routes.constants';
import { useContextualMenu } from '../../../../hooks/useContextualMenu';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { showModal } from '../../../../services/modal.service';
import { ShowModalPayload } from '../../../../states/modal/modalSlice';
import { userSelector } from '../../../../states/user/userSlice';
import Avatar from '../../../Avatar/Avatar';
import './AccountAction.scss';
import { ChangeLanguage } from './ChangeLanguage/ChangeLanguage';

export function AccountAction() {
  const user = useSelector(userSelector);
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [isAvatarMenuOpened, setAvatarMenuOpened] = useState(false);
  const [isHelpMenuOpened, setHelpMenuOpened] = useState(false);

  const helpMenuIconRef = useRef(null);
  const helpMenuRef = useRef(null);
  const avatarMenuRef = useRef(null);
  const avatarIconRef = useRef(null);

  useOutsideClick([helpMenuIconRef, helpMenuRef], () => setHelpMenuOpened(false));
  useOutsideClick([avatarIconRef, avatarMenuRef], () => setAvatarMenuOpened(false));

  const avatarClicked = useCallback(() => {
    const value = !isAvatarMenuOpened;
    setAvatarMenuOpened(value);
  }, [isAvatarMenuOpened]);

  const helpMenuClicked = useCallback(() => {
    const value = !isHelpMenuOpened;
    setHelpMenuOpened(value);
  }, [isHelpMenuOpened]);

  const signOutClicked = useCallback(() => {
    navigate(RouteConstants.Logout);
  }, [navigate]);

  const openChangeLanguageModal = () => {
    const showModalPayload: ShowModalPayload = {
      component: <ChangeLanguage />,
      props: { isBlocking: false },
      confirmEvent: () => {},
      title: translate('CaptionResource.ChangeLanguage'),
      description: translate('CaptionResource.ChangeLanguageDescription'),
    };
    showModal(showModalPayload);
  };

  const { menuProps: settingMenuProps } = useContextualMenu([
    {
      key: 'ChangeLanguage',
      text: translate('CaptionResource.ChangeLanguage'),
      onClick: openChangeLanguageModal,
      iconProps: { iconName: 'LocaleLanguage' },
    },
  ]);

  return (
    <div className="account-action-container">
      <ActionButton
        allowDisabledFocus
        className="text-color"
        onRenderMenuIcon={() => <></>}
        iconProps={{ iconName: 'PlayerSettings' }}
        menuProps={settingMenuProps}
      ></ActionButton>

      <ActionButton
        className="text-color"
        iconProps={{ iconName: 'Help' }}
        onClick={helpMenuClicked}
        elementRef={helpMenuIconRef}
      ></ActionButton>

      <div ref={helpMenuRef} className={'help-menu ' + (isHelpMenuOpened ? 'open' : '')}>
        <div className="close-button-wrapper">
          <ActionButton iconProps={{ iconName: 'ChromeClose' }} className="grey-130" onClick={helpMenuClicked}></ActionButton>
        </div>
        <span className="title text-three-dots">{translate('CommonResource.GetHelpMyAccount')}</span>
        <Link href={translate('CommonResource.GetHelpCommunityLinkMyAccount')} target="_blank" underline={true}>
          {translate('CommonResource.GetHelpCommunityMyAccount')}
        </Link>
        <div className="version">
          <span className="text-three-dots">{translate('CommonResource.VersionMyAccount')}</span>
          <span>{ApplicationVersion}</span>
        </div>
      </div>

      <ActionButton allowDisabledFocus elementRef={avatarIconRef}>
        <Avatar
          props={{ imageUrl: user.picture, size: PersonaSize.size32, initialsColor: 'hsl(0, 0%, 90%)' }}
          avatarClicked={avatarClicked}
        ></Avatar>
      </ActionButton>

      <div ref={avatarMenuRef} className="avatar-menu" style={{ display: isAvatarMenuOpened ? 'flex' : 'none' }}>
        <div className="right-controls">
          <ActionButton onClick={signOutClicked}>{translate('CommonResource.SignOut')}</ActionButton>
        </div>
        <div className="information">
          <Avatar props={{ imageUrl: user.picture, size: PersonaSize.size100, initialsColor: 'hsl(0, 0%, 90%)' }}></Avatar>
          <div className="name">
            <span>{user.fullName}</span>
            <span>{user.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
